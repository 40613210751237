import React, { Component } from "react";
import { Link } from "react-scroll";
import logo from "../images/logo.png";
import tree from "../images/tree.png";
import whatsapp from "../images/whatsapp.png";
import instagram from "../images/instagram.png";
import facebook from "../images/facebook.png";
import telegram from "../images/telegram.png";
import twitter from "../images/twitter.png";
import youtube from "../images/youtube.png";
import linkedin from "../images/linkedin.png";
class Footer extends React.Component {
  render() {
    return (
      <div
        className="container-fluid mb-3 bg-glass position-relative"
        id="footer"
      >
        <div className="row mt-5 d-flex p-2 justify-content-center">
          <div className="col-md-3 col-12">
            <img src={logo} alt="logo" width="300" height="72" />
            <p className="text-white px-3">
              354 , Bernard Avenue , Gst Road , Pasumalai, opposite to reliance
              petrol bunk, Madurai, Tamil Nadu 625004
            </p>
          </div>
          <div className="col-md-3 col-12">
            <p className="text-warning fw-bold">Useful Links</p>
            <ul>
              <li role="button">
                <Link className="nav-link text-white" spy={true} to="home">
                  Home
                </Link>
              </li>
              <li role="button">
                <Link className="nav-link text-white" spy={true} to="about-us">
                  About Us
                </Link>
              </li>
              <li role="button">
                <Link className="nav-link text-white" spy={true} to="work-flow">
                  Work Flow
                </Link>
              </li>
              <li role="button">
                <Link
                  className="nav-link text-white"
                  spy={true}
                  to="tech-stack"
                >
                  Tech Stack
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-12">
            <p className="text-warning fw-bold">Digital Connect</p>
            <ul className="p-0 m-0">
              <li className="d-inline-block px-2 pb-3" role="button">
                <a href="https://wa.me/+919344549658" target="_blank">
                  <img src={whatsapp} alt="whatsapp" width="50" height="50" />
                </a>
              </li>
              <li className="d-inline-block px-2 pb-3">
                <a href="https://instagram.com/intrithm" target="_blank">
                  <img src={instagram} alt="instagram" width="50" height="50" />
                </a>
              </li>
              <li className="d-inline-block px-2 pb-3">
                <a href="https://facebook.com/intrithm" target="_blank">
                  <img src={facebook} alt="facebook" width="50" height="50" />
                </a>
              </li>
              <li className="d-inline-block px-2 pb-3">
                <a href="https://twitter.com/intrithm" target="_blank">
                  <img src={twitter} alt="twitter" width="50" height="50" />
                </a>
              </li>
              <li className="d-inline-block px-2 pb-3 d-none">
                <img src={telegram} alt="telegram" width="50" height="50" />
              </li>
              <li className="d-inline-block px-2 pb-3">
                <a href="https://youtube.com/intrithm" target="_blank">
                  <img src={youtube} alt="youtube" width="50" height="50" />
                </a>
              </li>
              <li className="d-inline-block px-2 pb-3">
                <a href="https://linkedin.com/company/intrithm" target="_blank">
                  <img src={linkedin} alt="linkedin" width="50" height="50" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-12 d-flex justify-content-end">
            <img src={tree} alt="tree" width="200" height="200" />
          </div>
          <div className="col-12 text-center text-white">
            <p>
              Intrithm © Copyright {new Date().getFullYear()} | All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
