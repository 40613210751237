import { Link } from "react-scroll";
import light_bulb from "../images/light_bulb.png";
import black_ellipse from "../images/black-ellipse.svg";
import productone from "../images/product-thumb-one.png";

const Home = () => {
  return (
    <div className="row" id="home">
      <div className="col-12 col-md-6 position-relative">
        <div className="col-12 position-relative">
          <div className="circule violet"></div>
          <div className="circule red"></div>
          <h2 className="text-yellow hometitle">
            Bring Your Ideas To Online With Us!
          </h2>
          <div className="glass mt-5 w-100 position-relative"></div>
        </div>
        <div className="home-after-glass mt-sm-5">
          <p className="text-white">
            We build web and mobile application for your ideas with emerging
            technologies
          </p>
          <Link
            className="btn intrithm-btn btn-rounded mb-2 btn-warning"
            spy={true}
            to="work-flow"
            role="button"
          >
            Get Started !
          </Link>
        </div>
      </div>
      <div className="col-12 col-md-1"></div>
      <div className="col-12 col-md-5 d-flex align-items-center justify-content-center">
        <div className="yellow-card mt-5 position-relative">
          <div className="home-product-card one p-3">
            <img src={productone} alt="productone" className="product-image" />
          </div>
          <div className="home-product-card two p-3">
            <img src={productone} alt="productone" className="product-image" />
          </div>
          <div className="home-product-card four p-3">
            <img src={productone} alt="productone" className="product-image" />
          </div>
          <div className="home-product-card three p-3">
            <img src={productone} alt="productone" className="product-image" />
          </div>
          <button
            className="btn btn-lg intrithm-btn btn-rounded mb-2 btn-warning position-absolute bottom-0 start-25"
            type="button"
          >
            View Products
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
