import React, { Component } from "react";
import php from "../images/techimages/php.png";
import java from "../images/techimages/java.png";
import kotlin from "../images/techimages/kotlin.png";
import android from "../images/techimages/androidstudio.png";
import nodejs from "../images/techimages/nodejs.png";
import laravel from "../images/techimages/laravel.png";
import react from "../images/techimages/react.png";
import vuejs from "../images/techimages/vue.png";
import codeigniter from "../images/techimages/codeigniter.png";
import html5 from "../images/techimages/html.png";
import css3 from "../images/techimages/css.png";
import mysql from "../images/techimages/mysql.png";
import mangodb from "../images/techimages/mangodb.png";
import github from "../images/techimages/github.png";
import gitlab from "../images/techimages/gitlab.png";

class TechStack extends React.Component {
  render() {
    return (
      <div className="container-fluid bg-glass py-5" id="tech-stack">
        <p className="text-center text-white">Where we Work ?</p>
        <h5 className="text-yellow text-center">Our Tech Stack</h5>

        <div className="row mt-5 d-flex align-items-center justify-content-center">
          <div className="col-md-1 col-6">
            <div className="card card-rounded justify-content-center">
              <img src={php} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={java} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={kotlin} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={android} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={nodejs} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={laravel} alt="php" className="circule-img" />
            </div>
          </div>
        </div>

        <div className="row mt-5 d-flex align-items-center justify-content-center">
          <div className="col-md-1 col-6">
            <div className="card card-rounded justify-content-center">
              <img src={react} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={vuejs} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={codeigniter} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={html5} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={css3} alt="php" className="circule-img" />
            </div>
          </div>
        </div>

        <div className="row mt-5 mb-5 d-flex align-items-center justify-content-center">
          <div className="col-md-1 col-6">
            <div className="card card-rounded justify-content-center">
              <img src={mysql} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={mangodb} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={github} alt="php" className="circule-img" />
            </div>
          </div>
          <div className="col-md-1 col-6 offset-md-1">
            <div className="card card-rounded justify-content-center">
              <img src={gitlab} alt="php" className="circule-img" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TechStack;
