import "./App.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min";
import ContactUs from "./components/contactUs";
import Footer from "./components/footer";
import Header from "./components/header";
import Home from "./components/home";
import HowWeWork from "./components/howwework";
import TechStack from "./components/techStack";
import Trustability from "./components/trustability";

function App() {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <Home />
        <HowWeWork />
        <TechStack />
        <Trustability />
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}

export default App;
