import icon from "../images/work-one.png";
import icon1 from "../images/work-two.png";
import icon2 from "../images/work-three.png";
import icon3 from "../images/work-four.png";
import icon4 from "../images/work-five.png";
import icon5 from "../images/work-six.png";
import backone from "../images/back-one.png";
import blue_ellipse from "../images/blue-ellipse.svg";
import yellow_ellipse from "../images/yellow-ellipse.svg";
import light_blue_ellipse from "../images/light-blue-ellipse.svg";
const HowWeWork = () => {
  return (
    <div className="container-fluid position-relative" id="work-flow">
      <img
        src={blue_ellipse}
        alt="blue-ellipse"
        className="position-absolute start-0"
      />
      <img
        src={yellow_ellipse}
        alt="yellow-ellipse"
        className="position-absolute end-0"
      />
      <img
        src={light_blue_ellipse}
        alt="yellow-ellipse"
        className="position-absolute top-50 start-50 translate-middle"
      />
      <img
        src={blue_ellipse}
        alt="blue-ellipse"
        className="position-absolute bottom-0 end-0"
      />
      <p className="text-center text-white">How we work ?</p>
      <h5 className="text-yellow text-center">Our Productivity Work Flow</h5>

      <div className="row mt-5 d-flex justify-content-center">
        <div className="col-md-3 p-4 d-flex align-items-stretch">
          <div className="card m-2">
            <div className="card-body text-center">
              <img
                src={icon}
                width="130"
                height="130"
                className="mb-2"
                alt="..."
              />
              <h5 className="text-yellow work-title">
                0.Requirement Gathering
              </h5>
              <p className="text-white work-content m-2">
                Initial point at requirement gathering we ll schedule meeting
                with client over skype or google meet for detailed discussion
                about the process of determining what the project is going to
                achieve and make real things to happen. Also it involves.
                Getting input from all stakeholders, including customers,
                salespeople, industry experts, and programmers.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-3 p-4 d-flex align-items-stretch">
          <div className="card m-2">
            <div className="card-body text-center">
              <img
                src={icon1}
                width="130"
                height="130"
                className="mb-2"
                alt="..."
              />
              <h5 className="text-yellow work-title">1.Analysis & Planning</h5>
              <p className="text-white work-content m-2">
                Gathering all the specific details required for a new system as
                well as determining the first ideas for prototypes determines
                the cost and resources required for implementing the analyzed
                requirements. This includes all the specifications for software,
                hardware, and network requirements for the system they plan to
                build.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-3 p-4 d-flex align-items-stretch">
          <div className="card m-2">
            <div className="card-body text-center">
              <img
                src={icon2}
                width="130"
                height="130"
                className="mb-2"
                alt="..."
              />
              <h5 className="text-yellow work-title">2.Design & Development</h5>
              <p className="text-white work-content m-2">
                We will first outline the details for the overall application
                such as its User interfaces, System interfaces,Network and
                network requirements, Databases. The development stage is the
                part where we actually write code and build the application
                according to the earlier design documents and outlined
                specifications.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center">
        <div className="col-md-3 p-4 d-flex align-items-stretch">
          <div className="card m-2">
            <div className="card-body text-center">
              <img
                src={icon3}
                width="130"
                height="130"
                className="mb-2"
                alt="..."
              />
              <h5 className="text-yellow work-title">3.Testing & Feedback</h5>
              <p className="text-white work-content m-2">
                Applications must be tested to make sure that there wont be any
                bugs and that the end-user experience will not be affected at
                any situation. Testing can be done either in short duration or
                take a very long time depends on the application complexity.
                After testing we ll share the staging site to the client for
                their feedback or inputs.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-3 p-4 d-flex align-items-stretch">
          <div className="card m-2">
            <div className="card-body text-center">
              <img
                src={icon4}
                width="130"
                height="130"
                className="mb-2"
                alt="..."
              />
              <h5 className="text-yellow work-title">
                4.Deployment to Production
              </h5>
              <p className="text-white work-content m-2">
                After testing, different modules or designs will be integrated
                togather to make that complete software. And we ll host the
                appllication to the production environment and fix any technical
                issues at server end. And after final review and approval from
                client we ll make the software live for the endusers to the
                market.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-3 p-4 d-flex align-items-stretch">
          <div className="card m-2">
            <div className="card-body text-center">
              <img
                src={icon5}
                width="130"
                height="130"
                className="mb-2"
                alt="..."
              />
              <h5 className="text-yellow work-title">
                5.Support & Maintenance
              </h5>
              <p className="text-white work-content m-2">
                We are always ready to implement any changes that the software
                might need after deployment. Also we ll provide reliable support
                for the software when its live in the market.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
