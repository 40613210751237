import logo from "../logo.png";
import { Link } from "react-scroll";
import HowWeWork from "./howwework";
const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark sticky-top bg-black">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img src={logo} alt="site-logo" width="196" height="46" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-evenly"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav w-75 mb-2 mb-lg-0 justify-content-evenly">
            <li className="nav-item" data-toggle="collapse">
              <Link
                className="nav-link text-white"
                spy={true}
                to="home"
                role="button"
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li className="nav-item" role="button" data-toggle="collapse">
              <Link className="nav-link text-white" spy={true} to="work-flow">
                Work Flow
              </Link>
            </li>
            <li className="nav-item" role="button" data-toggle="collapse">
              <Link className="nav-link text-white" spy={true} to="tech-stack">
                Tech Stack
              </Link>
            </li>
            <li className="nav-item" role="button" data-toggle="collapse">
              <Link className="nav-link text-white" spy={true} to="about-us">
                About Us
              </Link>
            </li>
            <li className="nav-item" role="button" data-toggle="collapse">
              <Link className="nav-link text-white" spy={true} to="contact-us">
                Contact Us
              </Link>
            </li>
            <li className="nav-item" role="button" data-toggle="collapse">
              <Link className="nav-link text-white" spy={true} to="footer">
                Connect With Us
              </Link>
            </li>
          </ul>
          <a
            className="btn intrithm-btn btn-rounded btn-warning"
            href="https://freelancer.com/u/vaithiebby"
            target="_blank"
          >
            Hire Us
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;
