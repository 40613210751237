import React, { Component } from "react";
import axios from "axios";
import skype from "../images/skype.png";
import whatsapp from "../images/whatsapp.png";
import email from "../images/email.png";
import emoji from "../images/emoji.png";
import Joi from "joi-browser";

class ContactUs extends React.Component {
  state = {
    data: {
      name: "",
      email: "",
      message: "",
    },
    errors: {},
    issend: false,
  };

  schema = {
    name: Joi.string()
      .required()
      .error(() => {
        return {
          message: "Please enter your name",
        };
      }),
    email: Joi.string()
      .required()
      .email()
      .error(() => {
        return {
          message: "Please enter valid email",
        };
      }),
    message: Joi.string()
      .required()
      .error(() => {
        return {
          message: "Please enter your message",
        };
      }),
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema.name };
    const { error } = Joi.validate(obj, schema);
    console.log(error);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input.name, input.value);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit(e);
  };

  doSubmit = async (e) => {
    // SERVER BASED ACTION GOES HERE
    // IT WORKS AFTER VALIDATION FROM HANDLE SUBMIT
    try {
      //const response = await userService.register(this.state.data);
      const response = await axios.post(
        "https://admin.intrithm.com/api/contact",
        {
          name: this.state.data.name,
          email: this.state.data.email,
          message: this.state.data.message,
        }
      );
      this.setState({ issend: true });
      setTimeout(() => {
        this.setState({ issend: false });
      }, 2000);
      e.target.reset();
    } catch (error) {
      if (error.response.status && error.response.status == 400) {
        const custerrors = { ...this.state.errors };
        this.setState({
          errors: { email: error.response.data.errors.email[0] },
        });
      }
    }
  };

  render() {
    const { data, errors, issend } = this.state;

    return (
      <div className="container-fluid" id="contact-us">
        <p className="text-center text-white">Contact Us</p>
        <h5 className="text-yellow text-center">
          Let’s Explain Your Dream To us
        </h5>

        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-md-5 col-12">
            {issend && (
              <div className="alert alert-success">
                Your Request Submitted Successfully!!
              </div>
            )}
            <form onSubmit={this.handleSubmit}>
              <div className="form-group d-flex p-2">
                <i className="fa fa-user-circle fa-2x align-self-center text-yellow p-2"></i>
                <input
                  type="text"
                  className="form-control"
                  placeholder="your name..."
                  name="name"
                  onChange={this.handleChange}
                />
              </div>
              {errors.name && (
                <div className="d-flex justify-content-center">
                  <small className="text-danger flex-wrap">
                    Please enter your name
                  </small>
                </div>
              )}
              <div className="form-group d-flex p-2">
                <i className="fa fa-envelope-open fa-2x align-self-center text-yellow p-2"></i>
                <input
                  type="email"
                  className="form-control"
                  placeholder="email address..."
                  name="email"
                  onChange={this.handleChange}
                />
              </div>
              {errors.email && (
                <div className="d-flex justify-content-center">
                  <small className="text-danger flex-wrap">
                    Please enter valid email
                  </small>
                </div>
              )}
              <div className="form-group d-flex p-2">
                <i className="fa fa-paper-plane fa-2x align-self-top text-yellow p-2"></i>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  className="form-control"
                  placeholder="decribe your idea..."
                  onChange={this.handleChange}
                ></textarea>
              </div>
              {errors.message && (
                <div className="d-flex justify-content-center">
                  <small className="text-danger flex-wrap">
                    Please enter your message
                  </small>
                </div>
              )}
              <div className="form-group d-flex justify-content-center p-2">
                <button
                  className="btn btn-lg intrithm-btn btn-rounded btn-warning"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-5 col-12">
            <div className="card p-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={skype}
                        alt="skype-icon"
                        width="40"
                        height="40"
                      />
                      <div>
                        <small className="text-light">Skype</small>
                        <a
                          className="text-decoration-none"
                          href="skype:intrithm?chat"
                          target="_blank"
                        >
                          <h5 className="text-yellow">intrithm</h5>
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={whatsapp}
                        alt="skype-icon"
                        width="40"
                        height="40"
                      />
                      <div>
                        <small className="text-light">Whatsapp</small>
                        <a
                          className="text-decoration-none"
                          href="tel:+919344549658"
                        >
                          <h5 className="text-yellow">+919344549658</h5>
                        </a>
                      </div>
                    </div>
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={email}
                        alt="skype-icon"
                        width="40"
                        height="40"
                      />
                      <div>
                        <small className="text-light">Email </small>
                        <a
                          className="text-light text-decoration-none"
                          href="mailto:contact@intrithm.com"
                        >
                          <h5 className="text-yellow">contact@intrithm.com</h5>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <img src={emoji} alt="emoji" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
