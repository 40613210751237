import React, { Component } from "react";
import rocket from "../images/rocket.png";

class Trustability extends React.Component {
  render() {
    return (
      <div className="container-fluid" id="about-us">
        <p className="text-center text-white">Our Trustability</p>
        <h5 className="text-yellow text-center">Trusted By 100+ Clients</h5>
        <div className="row mt-5 d-flex align-items-center justify-content-center">
          <div className="col-md-6 col-12">
            <img className="img-fluid" src={rocket} alt="rocket-image" />
          </div>
          <div className="col-md-6 col-12">
            <div className="row">
              <div className="col-md-5 col-12">
                <div className="card-deep  mb-5 p-5 text-center">
                  <div className="card-body">
                    <h1 className="text-yellow fw-bold">100+</h1>
                    <p className="text-white pt-2">Projects Delivered</p>
                  </div>
                </div>
              </div>

              <div className="col-md-5 col-12">
                <div className="card-deep  mb-5 p-5 text-center">
                  <div className="card-body">
                    <h1 className="text-yellow fw-bold">35+</h1>
                    <p className="text-white pt-2">Global Clients</p>
                  </div>
                </div>
              </div>

              <div className="col-md-5 col-12">
                <div className="card-deep  mb-5 p-5 text-center">
                  <div className="card-body">
                    <h1 className="text-yellow fw-bold">25+</h1>
                    <p className="text-white pt-2">Countries Reached</p>
                  </div>
                </div>
              </div>

              <div className="col-md-5 col-12">
                <div className="card-deep  mb-5 p-5 text-center">
                  <div className="card-body">
                    <h1 className="text-yellow fw-bold">5+</h1>
                    <p className="text-white pt-2">Products Released</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Trustability;
